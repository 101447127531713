import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Subject, BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  url: any;
    urlname: any;
    public productArr = new BehaviorSubject<any>([]);

  constructor(public http: HttpClient) { }

  ShowAlert(title, text, type) {
    Swal.fire({
      title: title,
      text: text,
      icon: type,
      //confirmButtonText: 'Ok'
    })
  }
  DownloadFile(url: string): Observable<Blob> {
    // Set the response type to Blob
    return this.http.get(url, {
      responseType: 'blob'
    });
  }
  //ShowAlert(title, text, type) {
  //  Swal.fire({
  //    title: title,
  //    html: text,
  //    //icon: type,
  //    confirmButtonText: 'Ok'
  //  })
  //}
  ShowAlert1(title, text, type) {
    Swal.fire({
      title: title,
      html: text,
      //icon: type,
      confirmButtonText: 'Print',
      //confirmButtonText1: 'Cancel'
    })
    }
    GetProducts() {
        return this.productArr.asObservable();
    }
  //Global Method to retrieve data 
  GetData(url) {
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        debugger
        this.urlname = data.Webservice;
        this.url = this.urlname + url
        this.http.get(this.url).subscribe(data => {

          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
  PostData(url, PostParam) {
    debugger
    return new Promise((resolve, reject) => {
      debugger
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        debugger
        this.urlname = data.Webservice;
        url = data.Webservice + url
        this.http.post(url, PostParam).subscribe(data => {
          debugger
          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
  PostData2(url, PostParam, PostParam2) {
    debugger;
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        url = data.Webservice + url
        this.http.post(url, PostParam, PostParam2).subscribe(data => {
          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
}
