





import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';


export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },


 {
    path: '',
    component: PagesComponent,
    children: [
      { path: 'Admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule) },
        { path: 'Dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } },
        { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
     ]
  },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
    { path: 'Getbookingdetails', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
    { path: 'BookingId/:GetLRdetails', loadChildren: () => import('./pages/mailbox/mailbox.module').then(m => m.MailboxModule) },
    { path: '**', component: NotFoundComponent },
    

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      //preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      //relativeLinkResolution: 'legacy',
      useHash: true
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
