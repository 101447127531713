<div class="sidebar-outer">
  <a class="logo d-flex align-items-center" routerLink="/" (click)="closeSubMenus()" *ngIf="settings.theme.sidebarIsFixed">
    <!--<span class="start">SNF-</span><span class="ng">CRM</span>-->
    <img src="../../../../assets/img/SNFLogo1.jpg" alt="SNF-CRM" class="logo-image imggg">
  </a>
  <app-vertical-menu [menuItems]="menuItems"></app-vertical-menu>
</div>




<style>
 .imggg {
    height: 36px;
    width: 84px;
  }
</style>
